import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <h1>Page not found</h1>
    <p>
      Sorry, that page doesn&apos;t exist. Try the <Link to="/">home page</Link>{" "}
      to start again.
    </p>
  </Layout>
);

export default NotFoundPage;
